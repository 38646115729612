@charset 'utf-8';
@use "sass:math";

@import '_mixin.scss';
@import '_variables.scss';

@import '_fonts.scss';
@import '_overlays.scss';
@import '_footer.scss';
@import '_homepage.scss';
@import '_input.scss';
@import '_buttons.scss';




.timer{
   position:fixed;
   bottom:0;
   right:0;
   width:20px;
   background:blue;
   color:white;
   display:none;
   z-index:-9999;
}
