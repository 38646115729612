@charset "UTF-8";
/* -------------------------------------------------------------
  Sass CSS3 Mixins! The Cross-Browser CSS3 Sass Library
  By: Matthieu Aussaguel, http://www.mynameismatthieu.com, @matthieu_tweets

  List of CSS3 Sass Mixins File to be @imported and @included as you need

  The purpose of this library is to facilitate the use of CSS3 on different browsers avoiding HARD TO READ and NEVER
  ENDING css files

  note: All CSS3 Properties are being supported by Safari 5
  more info: http://www.findmebyip.com/litmus/#css3-properties

------------------------------------------------------------- */
@font-face {
  font-family: "Barlow";
  font-display: swap;
  src: url("static/fonts/Barlow-Bold.woff2") format("woff2"), url("static/fonts/Barlow-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Barlow";
  font-display: swap;
  src: url("static/fonts/Barlow-Medium.woff2") format("woff2"), url("static/fonts/Barlow-Medium.woff") format("woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Barlow";
  font-display: swap;
  src: url("static/fonts/Barlow-Regular.woff2") format("woff2"), url("static/fonts/Barlow-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Barlow";
  font-display: swap;
  src: url("static/fonts/Barlow-Thin.woff2") format("woff2"), url("static/fonts/Barlow-Thin.woff") format("woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Head";
  font-display: swap;
  src: url("static/fonts/head.woff2") format("woff2"), url("static/fonts/head.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}
#overlays {
  visibility: hidden;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: -33;
  -webkit-transition: z-index 0s 1s ease, visibility 0s 1s ease;
  -khtml-transition: z-index 0s 1s ease, visibility 0s 1s ease;
  -moz-transition: z-index 0s 1s ease, visibility 0s 1s ease;
  -ms-transition: z-index 0s 1s ease, visibility 0s 1s ease;
  -o-transition: z-index 0s 1s ease, visibility 0s 1s ease;
  transition: z-index 0s 1s ease, visibility 0s 1s ease;
}
#overlays.show {
  visibility: visible;
  z-index: 99;
  -webkit-transition: z-index 0s 0s ease, visibility 0s 0s ease;
  -khtml-transition: z-index 0s 0s ease, visibility 0s 0s ease;
  -moz-transition: z-index 0s 0s ease, visibility 0s 0s ease;
  -ms-transition: z-index 0s 0s ease, visibility 0s 0s ease;
  -o-transition: z-index 0s 0s ease, visibility 0s 0s ease;
  transition: z-index 0s 0s ease, visibility 0s 0s ease;
}
#overlays.show .bg {
  opacity: 0.7;
}
#overlays .bg {
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: #fff;
  opacity: 0;
  -webkit-transition: opacity 1s 0s ease;
  -khtml-transition: opacity 1s 0s ease;
  -moz-transition: opacity 1s 0s ease;
  -ms-transition: opacity 1s 0s ease;
  -o-transition: opacity 1s 0s ease;
  transition: opacity 1s 0s ease;
}
#overlays .overlay-type-1 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -khtml-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: rgb(20, 19, 21);
  visibility: hidden;
  z-index: -1;
  -webkit-transition: z-index 0s 1s ease, visibility 0s 1s ease;
  -khtml-transition: z-index 0s 1s ease, visibility 0s 1s ease;
  -moz-transition: z-index 0s 1s ease, visibility 0s 1s ease;
  -ms-transition: z-index 0s 1s ease, visibility 0s 1s ease;
  -o-transition: z-index 0s 1s ease, visibility 0s 1s ease;
  transition: z-index 0s 1s ease, visibility 0s 1s ease;
}
#overlays .overlay-type-1.show {
  visibility: visible;
  z-index: 99;
  -webkit-transition: z-index 0s 0s ease, visibility 0s 0s ease;
  -khtml-transition: z-index 0s 0s ease, visibility 0s 0s ease;
  -moz-transition: z-index 0s 0s ease, visibility 0s 0s ease;
  -ms-transition: z-index 0s 0s ease, visibility 0s 0s ease;
  -o-transition: z-index 0s 0s ease, visibility 0s 0s ease;
  transition: z-index 0s 0s ease, visibility 0s 0s ease;
}
#overlays .overlay-type-1.show .overlay-container {
  opacity: 1;
  -webkit-transform: translate(0%, 0);
  -khtml-transform: translate(0%, 0);
  -moz-transform: translate(0%, 0);
  -ms-transform: translate(0%, 0);
  -o-transform: translate(0%, 0);
  transform: translate(0%, 0);
  -webkit-transition: opacity 1s 0.6s ease, transform 0.8s 0.4s ease;
  -khtml-transition: opacity 1s 0.6s ease, transform 0.8s 0.4s ease;
  -moz-transition: opacity 1s 0.6s ease, transform 0.8s 0.4s ease;
  -ms-transition: opacity 1s 0.6s ease, transform 0.8s 0.4s ease;
  -o-transition: opacity 1s 0.6s ease, transform 0.8s 0.4s ease;
  transition: opacity 1s 0.6s ease, transform 0.8s 0.4s ease;
}
#overlays .overlay-type-1.show .overlay-close-btn {
  opacity: 1;
  -webkit-transform: translate(0%, 0);
  -khtml-transform: translate(0%, 0);
  -moz-transform: translate(0%, 0);
  -ms-transform: translate(0%, 0);
  -o-transform: translate(0%, 0);
  transform: translate(0%, 0);
  -webkit-transition: opacity 1s 0.6s ease, transform 0.8s 0.4s ease;
  -khtml-transition: opacity 1s 0.6s ease, transform 0.8s 0.4s ease;
  -moz-transition: opacity 1s 0.6s ease, transform 0.8s 0.4s ease;
  -ms-transition: opacity 1s 0.6s ease, transform 0.8s 0.4s ease;
  -o-transition: opacity 1s 0.6s ease, transform 0.8s 0.4s ease;
  transition: opacity 1s 0.6s ease, transform 0.8s 0.4s ease;
}
#overlays .overlay-type-1 .overlay-close-btn {
  position: absolute;
  width: 48px;
  height: 48px;
  top: -24px;
  right: -24px;
  border-radius: 50%;
  background-color: rgb(20, 19, 21);
  color: #fff;
  cursor: pointer;
  opacity: 0;
  -webkit-transform: translate(0%, -12px);
  -khtml-transform: translate(0%, -12px);
  -moz-transform: translate(0%, -12px);
  -ms-transform: translate(0%, -12px);
  -o-transform: translate(0%, -12px);
  transform: translate(0%, -12px);
  -webkit-transition: opacity 0.4s 0s ease, transform 0.4s 0s ease, background-color 0.4s 0s ease;
  -khtml-transition: opacity 0.4s 0s ease, transform 0.4s 0s ease, background-color 0.4s 0s ease;
  -moz-transition: opacity 0.4s 0s ease, transform 0.4s 0s ease, background-color 0.4s 0s ease;
  -ms-transition: opacity 0.4s 0s ease, transform 0.4s 0s ease, background-color 0.4s 0s ease;
  -o-transition: opacity 0.4s 0s ease, transform 0.4s 0s ease, background-color 0.4s 0s ease;
  transition: opacity 0.4s 0s ease, transform 0.4s 0s ease, background-color 0.4s 0s ease;
}
#overlays .overlay-type-1 .overlay-close-btn:hover {
  background-color: #00FC81;
}
#overlays .overlay-type-1 .overlay-close-btn span {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-transform: rotate(-45deg);
  -khtml-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  filter: progid:DXImageTransform.Microsoft.Matrix( M11=0, M12=0, M21=0, M22=0, sizingMethod="auto expand");
  zoom: 1;
  line-height: 48px;
  font-size: 2em;
  font-weight: 800;
}
#overlays .overlay-type-1 .overlay-container {
  opacity: 0;
  position: relative;
  max-height: 90vh;
  -webkit-transform: translate(0%, -12px);
  -khtml-transform: translate(0%, -12px);
  -moz-transform: translate(0%, -12px);
  -ms-transform: translate(0%, -12px);
  -o-transform: translate(0%, -12px);
  transform: translate(0%, -12px);
  -webkit-transition: opacity 0.4s 0s ease, transform 0.4s 0s ease;
  -khtml-transition: opacity 0.4s 0s ease, transform 0.4s 0s ease;
  -moz-transition: opacity 0.4s 0s ease, transform 0.4s 0s ease;
  -ms-transition: opacity 0.4s 0s ease, transform 0.4s 0s ease;
  -o-transition: opacity 0.4s 0s ease, transform 0.4s 0s ease;
  transition: opacity 0.4s 0s ease, transform 0.4s 0s ease;
  background: #fff;
  box-shadow: 0px 16px 24px -8px rgba(0, 0, 0, 0.2);
  padding: 0;
  border-radius: 24px;
  overflow-y: scroll;
}
.overlay#language-currency-overlay .overlay-container {
  padding: 24px;
}
.overlay#language-currency-overlay h4 {
  font-family: "Manrope", Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 12px;
}
@media only screen and (max-height: 600px) {
  .overlay#language-currency-overlay h4 {
    font-size: 16px;
    margin-bottom: 6px;
  }
}
.overlay#language-currency-overlay ul {
  margin-bottom: 12px;
}
.overlay#language-currency-overlay ul li {
  padding: 3px 0;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  -webkit-transition: all 0.4s 0s ease;
  -khtml-transition: all 0.4s 0s ease;
  -moz-transition: all 0.4s 0s ease;
  -ms-transition: all 0.4s 0s ease;
  -o-transition: all 0.4s 0s ease;
  transition: all 0.4s 0s ease;
}
@media only screen and (max-height: 600px) {
  .overlay#language-currency-overlay ul li {
    padding: 2px 0;
    line-height: 1.2em;
  }
}
.overlay#language-currency-overlay ul li:hover {
  color: #00FC81;
}
.overlay#language-currency-overlay .languages-switcher-list {
  display: flex;
  flex-flow: wrap;
}
.overlay#language-currency-overlay .languages-switcher-list li {
  flex: 1 0;
}
.overlay#language-currency-overlay .languages-switcher-list li.not-active .icon:before {
  opacity: 0;
}

.overlay#contact-overlay {
  max-width: 800px;
  width: calc(100% - 48px);
}
@media only screen and (max-width: 600px) {
  .overlay#contact-overlay {
    width: 100%;
    height: 100%;
    bottom: 0;
    top: auto;
    -webkit-transform: translate(-50%, 0);
    -khtml-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}
.overlay#contact-overlay.loading {
  filter: blur(3px);
}
@media only screen and (max-width: 600px) {
  .overlay#contact-overlay .overlay-close-btn {
    right: 24px;
    top: 24px;
    background-color: #00FC81;
  }
}
.overlay#contact-overlay .overlay-container {
  width: 100%;
  background: transparent;
  box-shadow: none;
  overflow: visible;
}
@media only screen and (max-width: 600px) {
  .overlay#contact-overlay .overlay-container {
    overflow: scroll;
    height: 90%;
    position: absolute;
    bottom: 0;
  }
}
.overlay#contact-overlay .text__container {
  width: 280px;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -khtml-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  height: calc(100% - 96px);
  padding: 24px 24px;
  color: #fff;
}
@media only screen and (max-width: 600px) {
  .overlay#contact-overlay .text__container {
    position: static;
    height: auto;
    top: 0;
    width: 100%;
    -webkit-transform: translate(0, 0%);
    -khtml-transform: translate(0, 0%);
    -moz-transform: translate(0, 0%);
    -ms-transform: translate(0, 0%);
    -o-transform: translate(0, 0%);
    transform: translate(0, 0%);
  }
}
.overlay#contact-overlay .text__container:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(20, 19, 21);
  box-shadow: 0px 16px 24px -8px rgba(0, 0, 0, 0.1);
  border-radius: 24px 0 0 24px;
  z-index: -1;
}
@media only screen and (max-width: 600px) {
  .overlay#contact-overlay .text__container:before {
    bottom: -30px;
  }
}
.overlay#contact-overlay .text__container h2 {
  color: #fff;
}
.overlay#contact-overlay .text__container .flag {
  width: 16px;
  height: 16px;
}
.overlay#contact-overlay .text__container .flag img {
  width: 16px;
  height: 16px;
}
.overlay#contact-overlay #contact-form {
  margin-left: 280px;
  padding: 24px 24px;
  display: flex;
  flex-flow: row wrap;
  background: #fff;
  border-radius: 24px;
  position: relative;
  z-index: 2;
  box-shadow: 0px 24px 54px 10px rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 720px) {
  .overlay#contact-overlay #contact-form {
    margin-left: 0;
  }
}
@media only screen and (max-width: 520px) {
  .overlay#contact-overlay #contact-form {
    padding: 12px 12px;
  }
}
.overlay#contact-overlay input[type=submit] {
  -webkit-appearance: none;
  appearance: none;
  padding: 0px 36px;
  border: solid 2px #FF5800;
  background: #FF5800;
  font-family: "Manrope", Arial, Helvetica, sans-serif;
  line-height: 36px;
  height: 40px;
  font-size: 14px;
  display: block;
  width: 100%;
  color: #fff;
  outline: none;
  border-radius: 20px;
  box-shadow: 0px 12px 24px -8px rgba(255, 88, 0, 0.8);
  position: absolute;
  bottom: -24px;
  left: 36px;
  text-transform: uppercase;
  width: auto;
  font-weight: 800;
  cursor: pointer;
  letter-spacing: 0.06em;
  -webkit-transition: all 0.8s 0s ease;
  -khtml-transition: all 0.8s 0s ease;
  -moz-transition: all 0.8s 0s ease;
  -ms-transition: all 0.8s 0s ease;
  -o-transition: all 0.8s 0s ease;
  transition: all 0.8s 0s ease;
}
.overlay#contact-overlay input[type=submit]:hover {
  letter-spacing: 0.1em;
}
@media only screen and (max-width: 600px) {
  .overlay#contact-overlay input[type=submit] {
    position: relative;
    bottom: auto;
  }
}

.overlay#contact-thanks-overlay {
  max-width: 400px;
  width: calc(100% - 24px);
}
.overlay#contact-thanks-overlay .overlay-container {
  padding: 24px 24px 24px 24px;
  background: rgb(20, 19, 21);
  overflow: visible;
}
.overlay#contact-thanks-overlay .overlay-container .not-active {
  display: none;
}
.overlay#contact-thanks-overlay .overlay-container .text__container h2 {
  margin-bottom: 0.5em;
  color: #fff;
}
.overlay#contact-thanks-overlay .overlay-container .text__container p {
  color: #fff;
  font-weight: 600;
  margin: 1em 0 1.5em;
}
.overlay#contact-thanks-overlay .overlay-container .button {
  position: absolute;
}

footer {
  margin-top: -20px;
  font-size: 0.9em;
}
footer p {
  padding-bottom: 4px;
  text-align: center;
}
footer p a {
  color: #404044;
  font-weight: 400;
}

section#home__intro {
  height: 92vh;
  max-height: 720px;
}
section#home__intro .background {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  -webkit-transform: translateZ(0px);
  -khtml-transform: translateZ(0px);
  -moz-transform: translateZ(0px);
  -ms-transform: translateZ(0px);
  -o-transform: translateZ(0px);
  transform: translateZ(0px);
}
section#home__intro .background .background__container {
  position: absolute;
  top: 0;
  bottom: 0%;
  right: 0;
  left: 0;
  -webkit-transform: translateZ(0px);
  -khtml-transform: translateZ(0px);
  -moz-transform: translateZ(0px);
  -ms-transform: translateZ(0px);
  -o-transform: translateZ(0px);
  transform: translateZ(0px);
}
section#home__intro .background .background__container:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(20, 19, 21, 0.7);
  background: linear-gradient(to bottom, rgba(20, 19, 21, 0.7) 0%, rgba(20, 19, 21, 0.7) 20%, rgba(20, 19, 21, 0.7) 80%, rgba(20, 19, 21, 0.7) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
section#home__intro .background .background__container .background-rotate__container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: translateZ(0px);
  -khtml-transform: translateZ(0px);
  -moz-transform: translateZ(0px);
  -ms-transform: translateZ(0px);
  -o-transform: translateZ(0px);
  transform: translateZ(0px);
}
section#home__intro .background .background__container img {
  margin: 0;
  border: 0;
  padding: 0;
  position: absolute;
  top: 0%;
  bottom: 0%;
  right: 0%;
  left: 0%;
  object-fit: cover;
  object-position: center top;
  height: 100%;
  width: 100%;
}
section#home__intro .background .background__container .video__container {
  position: absolute;
  width: 100%;
  height: 120%;
  top: 0;
  left: 0;
}
section#home__intro .background .background__container .video__container video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}
section#home__intro .text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -khtml-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 4;
  color: #fff;
  width: 100%;
}
@media only screen and (max-width: 600px) {
  section#home__intro .text-overlay {
    -webkit-transform: translate(-50%, calc(-50% - 30px));
    -khtml-transform: translate(-50%, calc(-50% - 30px));
    -moz-transform: translate(-50%, calc(-50% - 30px));
    -ms-transform: translate(-50%, calc(-50% - 30px));
    -o-transform: translate(-50%, calc(-50% - 30px));
    transform: translate(-50%, calc(-50% - 30px));
  }
}
section#home__intro .text-overlay h1 {
  text-align: left;
  color: #fff;
  width: 80%;
  max-width: 720px;
  display: block;
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  section#home__intro .text-overlay h1 {
    line-height: 1.1em;
  }
}
section#home__intro .text-overlay h1 .green {
  color: #00FC81;
}
section#home__intro .text-overlay h2 {
  text-align: center;
  color: #fff;
  margin-bottom: 0.2em;
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
  font-size: 1.2em;
}
section#home__intro .text-overlay p {
  font-size: 18px;
  font-size: calc(16px + (18 - 16) * ((100vw - 375px) / (1440 - 375)));
  color: #fff;
  font-weight: 500;
  width: 100%;
  max-width: 500px;
  text-align: left;
  margin: 32px 0 32px;
  display: block;
}
@media screen and (max-width: 375px) {
  section#home__intro .text-overlay p {
    font-size: 16px;
  }
}
@media screen and (min-width: 1440px) {
  section#home__intro .text-overlay p {
    font-size: 18px;
  }
}
section#home__intro .text-overlay .button__container {
  width: 100%;
  text-align: left;
}

section#home__services {
  padding: calc(3vw + 60px) 0 0;
}
section#home__services .section-text__container p {
  max-width: 600px;
}
section#home__services .section-text__container a {
  color: rgb(20, 19, 21);
  position: relative;
}
section#home__services .section-text__container a:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: #00FC81;
}
section#home__services .image__container {
  width: 100%;
  height: 500px;
  overflow: hidden;
  position: relative;
  -webkit-transform: translateZ(0px);
  -khtml-transform: translateZ(0px);
  -moz-transform: translateZ(0px);
  -ms-transform: translateZ(0px);
  -o-transform: translateZ(0px);
  transform: translateZ(0px);
  display: none;
}
section#home__services .image__container:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(19, 20, 44, 0.6);
}
section#home__services .image__container .parallax__container {
  position: absolute;
  top: -20%;
  left: 0;
  right: 0;
  bottom: 0;
}
section#home__services .image__container img {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  object-position: center center;
  filter: grayscale(100%);
}
section#home__services .process__container {
  padding: 0 0 0px;
}
section#home__services .process__container ul {
  display: flex;
  flex-flow: row wrap;
  gap: 4%;
}
section#home__services .process__container ul li {
  flex: 0 0 auto;
  padding: 0;
  max-width: 48%;
  margin-top: 24px;
}
@media only screen and (max-width: 600px) {
  section#home__services .process__container ul li {
    max-width: 100%;
  }
}
section#home__services .process__container ul li .icon {
  width: 100%;
  text-align: left;
}
section#home__services .process__container ul li .icon svg {
  margin: 0 auto 12px;
  width: 52px;
  height: 100%;
  fill: rgb(20, 19, 21);
  opacity: 1;
}
section#home__services .process__container ul li .icon img {
  width: 52px;
  height: 52px;
  margin: 0 auto 12px;
}
section#home__services .process__container ul li .title {
  position: relative;
  text-align: left;
  font-weight: 800;
  color: #1B1A27;
  line-height: 1.2em;
}
section#home__services .process__container ul li .title .number {
  display: inline-block;
}
section#home__services .process__container ul li .title .number:after {
  content: ".";
}
section#home__services .process__container ul li .description {
  text-align: left;
  margin: 12px 0;
  padding-right: 12px;
  font-size: 15px;
}

.services-box {
  width: 100%;
  margin: 0px 0;
}
.services-box .services-box__container {
  display: flex;
  flex-flow: row wrap;
  width: calc(100% + 24px);
  margin-left: -12px;
  display: none;
}
.services-box .service {
  display: flex;
  flex: 1 0 33.33%;
  margin-bottom: 36px;
  position: relative;
}
.services-box .service:hover .bg {
  opacity: 1;
}
.services-box .service:hover .more-info {
  opacity: 1;
}
.services-box .service .bg {
  position: absolute;
  top: -12px;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0px 40px 24px -6px rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  background: rgb(27, 26, 39);
  z-index: 1;
  opacity: 0;
  -webkit-transition: all 0.4s 0s ease;
  -khtml-transition: all 0.4s 0s ease;
  -moz-transition: all 0.4s 0s ease;
  -ms-transition: all 0.4s 0s ease;
  -o-transition: all 0.4s 0s ease;
  transition: all 0.4s 0s ease;
}
.services-box .service .inner {
  position: relative;
  padding: 0 12px 12px;
  z-index: 2;
}
.services-box .service .more-info {
  position: absolute;
  bottom: -24px;
  left: 12px;
  right: 12px;
  color: rgb(20, 19, 21);
  cursor: pointer;
  font-weight: 700;
  opacity: 0;
  border-radius: 3px;
  text-align: center;
  background: #00FC81;
  padding: 6px 6px;
  -webkit-transition: all 0.4s 0s ease;
  -khtml-transition: all 0.4s 0s ease;
  -moz-transition: all 0.4s 0s ease;
  -ms-transition: all 0.4s 0s ease;
  -o-transition: all 0.4s 0s ease;
  transition: all 0.4s 0s ease;
}

#home__team {
  padding: calc(3vw + 30px) 0;
}

.team-box {
  width: 100%;
  margin: 32px 0 0px;
  position: relative;
}
.team-box .team-box__container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-left: 0;
  justify-content: flex-start;
  gap: 5%;
}
.team-box .person {
  display: block;
  flex: 0 0 160px;
  position: relative;
}
.team-box .person .image {
  height: 0;
  width: 100%;
  padding-top: 140%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
}
.team-box .person .parallax__container {
  position: absolute;
  top: -20%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 120%;
}
.team-box .person img {
  position: relative;
  filter: grayscale(0);
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: rgb(20, 19, 21);
  opacity: 1;
  z-index: 4;
  object-fit: cover;
}
.team-box .person .inner {
  position: relative;
  padding: 12px 0 24px;
  z-index: 2;
  width: 100%;
  text-align: left;
}
.team-box .person .inner h3 {
  text-align: left;
  padding: 0;
  margin-bottom: 0;
  position: relative;
  display: inline-block;
}
.team-box .person .inner p {
  margin: 0;
}
.team-box .person .inner .button__container {
  text-align: left;
  padding: 0 12px 0 0;
  margin: 0 0 0px;
}
.team-box .person .inner .button__container a {
  color: #404044;
  margin-right: 1em;
}
.team-box .person .inner .button__container a:hover {
  color: #00FC81;
}
.team-box .person .inner .button__container a:last-child {
  margin-right: 0;
}

§ #home__resultaten {
  padding: calc(3vw + 30px) 0 calc(3vw + 30px) 0;
}

.result-box {
  margin: 0px auto 0;
  position: relative;
}
.result-box .result-box__container {
  display: flex;
  flex-flow: row wrap;
  margin-top: 0px;
  width: 100%;
  max-width: 720px;
  margin: 0px auto;
  gap: 24px;
}
.result-box .result {
  display: block;
  flex: 1 1 calc(50% - 12px);
  margin-bottom: 0px;
  position: relative;
  flex-flow: row nowrap;
  cursor: default;
  -webkit-transform: translateZ(0);
  -khtml-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
.result-box .result.clickable {
  cursor: pointer;
}
.result-box .result.clickable:hover .more-info {
  opacity: 1;
}
.result-box .result:nth-child(even) {
  transform: translate(0, 120px);
}
.result-box .result .bg {
  display: block;
  border-radius: 12px;
  width: 100%;
  height: 0;
  padding-top: 120%;
  overflow: hidden;
  z-index: 3;
  position: relative;
  top: 0;
  left: 50%;
  margin: 0 0;
  -webkit-transform: translate(-50%, 0%);
  -khtml-transform: translate(-50%, 0%);
  -moz-transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  -o-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
  -webkit-transition: all 1.5s 0s ease;
  -khtml-transition: all 1.5s 0s ease;
  -moz-transition: all 1.5s 0s ease;
  -ms-transition: all 1.5s 0s ease;
  -o-transition: all 1.5s 0s ease;
  transition: all 1.5s 0s ease;
}
.result-box .result .bg .more-info {
  cursor: pointer;
  background: #00FC81;
  color: rgb(20, 19, 21);
  padding: 0px 24px;
  display: block;
  position: absolute;
  top: 50%;
  border-radius: 20px;
  height: 40px;
  display: flex;
  align-items: center;
  left: 50%;
  opacity: 0;
  font-family: "Manrope", "Barlow", "AvenirNext", Arial, Helvetica, sans-serif;
  white-space: nowrap;
  -webkit-transition: all 1s 0s ease;
  -khtml-transition: all 1s 0s ease;
  -moz-transition: all 1s 0s ease;
  -ms-transition: all 1s 0s ease;
  -o-transition: all 1s 0s ease;
  transition: all 1s 0s ease;
  -webkit-transform: translate(-50%, -50%);
  -khtml-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.result-box .result .bg .parallax__container {
  position: absolute;
  top: -20%;
  left: 0%;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 120%;
}
.result-box .result .bg img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  display: block;
  filter: grayscale(0%);
  -webkit-transform: scale(1);
  -khtml-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 1s 0s ease;
  -khtml-transition: all 1s 0s ease;
  -moz-transition: all 1s 0s ease;
  -ms-transition: all 1s 0s ease;
  -o-transition: all 1s 0s ease;
  transition: all 1s 0s ease;
}
.result-box .result .inner {
  display: block;
  position: relative;
  z-index: 1;
  padding: 12px 0px 24px 0px;
  width: 100%;
  margin: 0 auto;
}
.result-box .result .inner p {
  display: none;
}

#home__contact {
  padding: calc(3vw + 30px) 0 calc(3vw + 30px) 0;
  position: relative;
}
#home__contact .section-phone__container {
  max-width: 600px;
  width: 100%;
  text-align: center;
  margin: 0 auto 60px;
}
#home__contact .section-phone__container .fa {
  transform: scale(0.8);
  margin-right: 0.25em;
}
#home__contact .section-phone__container a {
  color: rgb(20, 19, 21);
  font-size: 2.4em;
  font-weight: 900;
  text-align: center;
  position: relative;
  z-index: 3;
}
#home__contact .section-phone__container a .shadow {
  position: absolute;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #00FC81;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  color: transparent;
  z-index: -1;
}
#home__contact .section-phone__container a .shadow:after {
  content: "";
  position: absolute;
  bottom: -4px;
  width: 100%;
  left: 0;
  background: #00FC81;
  height: 3px;
}
#home__contact .section-address__container {
  display: flex;
  max-width: 600px;
  margin: 24px auto 24px;
  width: 100%;
  padding: 0 12px;
}
#home__contact .section-address__container .column {
  flex: 1 1 50%;
  text-align: left;
}
#home__contact .section-address__container .column p {
  text-align: left;
}
#home__contact .section-address__container .column a {
  color: rgb(20, 19, 21);
  position: relative;
}
#home__contact .section-address__container .column a:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: #00FC81;
}
#home__contact form {
  width: 100%;
  max-width: 600px;
  margin: 0 auto 90px;
}
#home__contact form label {
  width: 100%;
  display: block;
  margin: 18px 0;
}
#home__contact form label .title {
  display: none;
  width: 100%;
}
#home__contact form label input, #home__contact form label textarea {
  width: 100%;
}
#home__contact form label input.empty, #home__contact form label textarea.empty {
  border-color: #E03939;
}
#home__contact form label textarea {
  height: 160px;
  resize: none;
}
#home__contact form label input[type=submit] {
  background: #00FC81;
  color: rgb(20, 19, 21);
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
  border-color: transparent;
  letter-spacing: 0.2em;
}

input[type=text], input[type=submit], input[type=email], textarea, select {
  -webkit-appearance: none;
  appearance: none;
  padding: 0px 20px;
  border: solid 1px rgb(20, 19, 21);
  background: transparent;
  font-family: "Manrope", Arial, Helvetica, sans-serif;
  line-height: 40px;
  color: rgb(20, 19, 21);
  height: 44px;
  display: inline-block;
  outline: none;
  border-radius: 22px;
  -webkit-transition: all 0.4s 0s ease;
  -khtml-transition: all 0.4s 0s ease;
  -moz-transition: all 0.4s 0s ease;
  -ms-transition: all 0.4s 0s ease;
  -o-transition: all 0.4s 0s ease;
  transition: all 0.4s 0s ease;
}
input[type=text]::placeholder, input[type=submit]::placeholder, input[type=email]::placeholder, textarea::placeholder, select::placeholder {
  color: #404044;
}
input[type=text]:focus, input[type=submit]:focus, input[type=email]:focus, textarea:focus, select:focus {
  border-color: rgb(20, 19, 21);
}
input[type=text].empty, input[type=submit].empty, input[type=email].empty, textarea.empty, select.empty {
  border-color: #E03939;
}
input[type=text]:disabled, input[type=submit]:disabled, input[type=email]:disabled, textarea:disabled, select:disabled {
  filter: grayscale(100%);
  cursor: not-allowed;
}

.button__container {
  display: block;
  margin: 0px 0 0;
  width: 100%;
}
.button__container .button {
  margin-right: 24px;
}
.button__container .button:last-child {
  margin-right: 0;
}

.button {
  text-decoration: none;
  padding: 0 22px 0 24px;
  position: relative;
  line-height: 36px;
  height: 38px;
  display: inline-block;
  color: #00FC81;
  font-weight: 400;
  font-family: "Manrope", "Barlow", "AvenirNext", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  font-size: calc(13px + (14 - 13) * ((100vw - 375px) / (1440 - 375)));
  letter-spacing: 0.25em;
  -webkit-transition: color 0.4s 0s ease;
  -khtml-transition: color 0.4s 0s ease;
  -moz-transition: color 0.4s 0s ease;
  -ms-transition: color 0.4s 0s ease;
  -o-transition: color 0.4s 0s ease;
  transition: color 0.4s 0s ease;
  -webkit-transform: translateZ(0);
  -khtml-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  border-radius: 20px;
  cursor: pointer;
}
@media screen and (max-width: 375px) {
  .button {
    font-size: 13px;
  }
}
@media screen and (min-width: 1440px) {
  .button {
    font-size: 14px;
  }
}
.button span.layer {
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  bottom: -1px;
  border: solid 1px #00FC81;
  border-radius: 22px;
  opacity: 1;
  z-index: -1;
  -webkit-transition: all 0.4s 0s ease;
  -khtml-transition: all 0.4s 0s ease;
  -moz-transition: all 0.4s 0s ease;
  -ms-transition: all 0.4s 0s ease;
  -o-transition: all 0.4s 0s ease;
  transition: all 0.4s 0s ease;
  overflow: hidden;
}
.button span.layer:after {
  content: "";
  position: absolute;
  top: 100%;
  left: -2px;
  width: calc(100% + 4px);
  height: 200%;
  background: #00FC81;
  z-index: -1;
  opacity: 1;
  -webkit-transform: skewY(-8deg);
  -khtml-transform: skewY(-8deg);
  -moz-transform: skewY(-8deg);
  -ms-transform: skewY(-8deg);
  -o-transform: skewY(-8deg);
  transform: skewY(-8deg);
  transform-origin: right top;
  -webkit-transition: all 0.8s 0s cubic-bezier(0.19, 1, 0.22, 1);
  -khtml-transition: all 0.8s 0s cubic-bezier(0.19, 1, 0.22, 1);
  -moz-transition: all 0.8s 0s cubic-bezier(0.19, 1, 0.22, 1);
  -ms-transition: all 0.8s 0s cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: all 0.8s 0s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.8s 0s cubic-bezier(0.19, 1, 0.22, 1);
}
.button span.text {
  line-height: 38px;
  height: 38px;
  display: block;
}
.button.dark span.layer {
  border-color: rgb(20, 19, 21);
}
.button.dark span.layer:after {
  background: rgb(20, 19, 21);
}
.button.dark:hover span.text {
  color: #fff;
}
.button.subdark span.layer {
  border-color: rgb(20, 19, 21);
}
.button.subdark span.layer:after {
  background: rgb(20, 19, 21);
}
.button.subdark:hover span.text {
  color: #fff;
}
.button.border-only {
  color: #fff;
}
.button.border-only:hover {
  color: rgb(20, 19, 21);
}
.button.border-only:hover .layer {
  top: -2px;
  bottom: -2px;
  left: 0px;
  right: 0px;
}
.button.border-only:hover .layer:after {
  top: -100%;
}
.button.white {
  color: #fff;
}
.button.white span.layer {
  border-color: #fff;
}
.button.white span.layer:after {
  background: #fff;
}
.button.white:hover span.text {
  color: rgb(20, 19, 21);
}

.timer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 20px;
  background: blue;
  color: white;
  display: none;
  z-index: -9999;
}