footer{
  margin-top:-20px;
  font-size:0.9em;
  p{
    padding-bottom:4px;
    text-align:center;
    a{
      color:$textColor;
      font-weight:400;
    }
  }
}
