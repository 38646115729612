section#home__intro{
  height:92vh;
  max-height:720px;
  .background{
    position:absolute;
    top:0;
    bottom:0;
    right:0;
    left:0;
    @include transform(translateZ(0px));
    .background__container{
      position:absolute;
      top:0;
      bottom:0%;
      right:0;
      left:0;
      @include transform(translateZ(0px));
      //perspective:800px;
      &:after{
        content:"";
        position:absolute;
        top:0;
        bottom:0;
        right:0;
        left:0;

        background:rgba(20,19,21,0.7);
background: linear-gradient(to bottom, rgba(20,19,21,0.7) 0%,rgba(20,19,21,0.7) 20%,rgba(20,19,21,0.7) 80%,rgba(20,19,21,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      }
      .background-rotate__container{
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        @include transform(translateZ(0px));
        //@include transition(all 0.2s 0s ease);
      }
      img{
        margin:0;
        border:0;
        padding:0;
        position:absolute;
        top:0%;
        bottom:0%;
        right:0%;
        left:0%;
        object-fit: cover;
        object-position: center top;
        height:100%;
        width:100%;

      }
      .video__container{
          position:absolute;
          width:100%;
          height:120%;
          top:0;
          left:0;
          video{
              position:absolute;
              width:100%;
              height:100%;
              top:0;
              left:0;
              object-fit: cover;
          }
      }
    }
  }
  .text-overlay{
    position:absolute;
    top:50%;
    left:50%;
    @include transform(translate(-50%, -50%));
    z-index:4;
    color:$white;
    width:100%;
    @media only screen and (max-width:600px){
  		@include transform(translate(-50%, calc(-50% - 30px)));

  	}
    h1{
      text-align:left;
      color:$white;
      width:80%;
      max-width:720px;
      display:block;
      margin-bottom:0;
      @media only screen and (max-width:767px){
        line-height:1.1em;
      }
      .green{
        color:$green;
      }
    }
    h2{
      text-align:center;
      color:$white;
      margin-bottom:0.2em;
      font-weight:700;
      font-style:italic;
      text-transform:uppercase;
      font-size:1.2em;
    }
    p{
      @include fluid-font(16px, 18px );
      color:$white;
      font-weight:500;
      width:100%;

      max-width:500px;
      text-align:left;
      margin:32px 0 32px;
      display:block;
    }
    .button__container{
      width:100%;
      text-align:left;
      //display:none;

    }
  }
}


section#home__services{
  padding:calc(3vw + 60px) 0 0;
  .section-text__container{
    p{
      max-width:600px;
    }
    a{
      color:$black;
      position:relative;
      &:before{
        content:"";
        position:absolute;
        bottom:0;
        left:0;
        right:0;
        height:1px;
        background:$green;
      }
    }
  }
  .image__container{
    width:100%;
    height:500px;
    overflow:hidden;
    position:relative;
    @include transform(translateZ(0px));
    display:none;
    &:after{
      content:"";
      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      background:rgba(19,20,44,0.6);
    }
    .parallax__container{
      position:absolute;
      top:-20%;
      left:0;
      right:0;
      bottom:0;
    }
    img{
      display:block;
      position:absolute;
      height:100%;
      width:100%;
      top:0;
      left:0;
      right:0;
      bottom:0;
      object-fit:cover;
      object-position:center center;
      filter:grayscale(100%);
    }
  }
  .process__container{
    padding:0 0 0px;
    ul{
      display:flex;
      flex-flow:row wrap;
      gap:4%;
      li{
        flex:0 0 auto;
        padding:0;
        max-width:48%;
        margin-top:24px;
        @media only screen and (max-width:600px){
          max-width:100%;
        }
        
        .icon{
          width:100%;
          text-align:left;
         
          svg{
            margin:0 auto 12px;
            width:52px;
            height:100%;
            fill:$black;
            opacity:1;
            //stroke-width:1.5px;

          }
          img{
            width:52px;
            height:52px;
            margin:0 auto 12px;
            
          }
        }
        .title{
          position:relative;
          
          text-align:left;
          font-weight:800;
          color:$black2;
          line-height:1.2em;
          .number{
            display:inline-block;
            &:after{
              content:".";

            }
            
          }
        }
        .description{
          text-align:left;
          margin: 12px 0;
          padding-right:12px;
          font-size:15px;
          
        }
      }
    }
  }

}



.services-box{
  width:100%;
  margin:0px 0;

  .services-box__container{
    display:flex;
    flex-flow:row wrap;
    width:calc(100% + 24px);
    margin-left:-12px;
    display:none;
  }
  .service{
    display:flex;
    flex:1 0 33.33%;
    margin-bottom:36px;
    position:relative;
    &:hover{
      .bg{
        opacity:1;
      }
      .more-info{
        opacity:1;
      }
    }
    .bg{
      position:absolute;
      top:-12px;
      left:0;
      right:0;
      bottom:0;
      box-shadow:0px 40px 24px -6px rgba(0, 0, 0, 0.4);
      border-radius:6px;
      background:rgba(27,26,39,1);
      z-index:1;
      opacity:0;
      @include transition(all 0.4s 0s ease);
    }
    .inner{
      position:relative;
      padding:0 12px 12px;
      z-index:2;
      .count{

      }
      h3{

      }
      p{

      }
    }
    .more-info{
      position:absolute;
      bottom:-24px;
      left:12px;
      right:12px;
      color:$black;
      cursor:pointer;
      font-weight:700;
      opacity:0;
      border-radius:3px;
      text-align:center;
      background:$green;
      padding:6px 6px;
      @include transition(all 0.4s 0s ease);
    }
  }
}




#home__team{
  padding:calc(3vw + 30px) 0;
}

.team-box{
  width:100%;
  margin:32px 0 0px;
  position:relative;
  .team-box__container{
    display:flex;
    flex-flow:row wrap;
    width:calc(100%);
    margin-left:0;
    justify-content:flex-start;
    gap:5%;
  }
  .person{
    display:block;
    flex:0 0 160px;
    position:relative;

   
    .image{
      height:0;
      width:100%;
      padding-top:140%;
      margin:0 auto;
      position:relative;
      overflow:hidden;
      border-radius:12px;
    }
    .parallax__container{
      position:absolute;
      top:-20%;
      left:0;
      right:0;
      bottom:0;
      width:100%;
      height:120%;
    }
    img{
      position:relative;
      filter:grayscale(00);
      width:100%;
      height:100%;
      margin:0 auto;
      background:$black;
      opacity:1;
      z-index:4;
      object-fit:cover;


    }
    .inner{
      position:relative;
      padding:12px 0 24px;
      z-index:2;

      width:100%;
      text-align:left;

      h3{
        text-align:left;
        padding:0;
        margin-bottom:0;
        position:relative;
        display:inline-block;
      }
      p{
        margin:0;
      }
      .button__container{
        text-align:left;
        padding:0 12px 0 0;
        margin:0 0 0px;
        a{
          color:rgba($textColor,1);
          margin-right:1em;
          &:hover{
            color:$green;
          }
          &:last-child{
            margin-right:0;
          }
        }
      }
    }
  }
}§


#home__resultaten{
  padding:calc(3vw + 30px) 0 calc(3vw + 30px) 0;
}



.result-box{
  margin:0px auto 0;
  position:relative;
  .result-box__container{
    display:flex;
    flex-flow:row wrap;
    margin-top:0px;
    width:100%;
    max-width:720px;
    margin:0px auto;
    gap:24px;

  }
  .result{
    display:block;
    flex:1 1 calc(50% - 12px);
    margin-bottom:0px;
    position:relative;
    flex-flow:row nowrap;
    cursor:default;
    &.clickable{
      cursor:pointer;
      &:hover{
        .more-info{
          opacity:1;
        }
      }
    }

    @include transform(translateZ(0));
    &:nth-child(even){
      //flex-flow:row-reverse wrap;
      //margin-top:-120px;
      transform:translate(0, 120px);
      @media only screen and (max-width:500px){
        //margin-top:0;
      }
    }

    @media only screen and (max-width:500px){
      //flex:1 1 100%;
      //margin-bottom:30px;
    }
    &:hover{
      .bg{
        //width:100%;
        img{
         // filter:grayscale(0%);
         // @include transform(scale(1.1));
        }
        .parallax__container{
          
        }

      }
    }
    .bg{
      display:block;
      border-radius:12px;
      width:100%;
      height:0;
      padding-top:120%;
      overflow:hidden;
      z-index:3;
      position:relative;
      top:0;
      left:50%;
      margin:0 0;
      @include transform(translate(-50%, 0%));
      @include transition(all 1.5s 0s ease);
      .more-info{
        cursor:pointer;
        background:$green;
        color:$black;
        padding:0px 24px;
        display:block;
        position:absolute;
        top:50%;
        border-radius:20px;
        height:40px;
        display:flex;
        align-items:center;
        left:50%;
        opacity:0;
        font-family:$headFont;
        white-space:nowrap;

        @include transition(all 1s 0s ease);
        @include transform(translate(-50%, -50%));

      }
      .parallax__container{
        position:absolute;
        top:-20%;
        left:0%;
        right:0;
        bottom:0;
        width:100%;
        height:120%;
      }
      img{
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        width:100%;
        height:100%;

        object-fit: cover;
        object-position: center center;
        display:block;
        filter:grayscale(0%);
        @include transform(scale(1));
        @include transition(all 1s 0s ease);



      }
    }
    .inner{
      display:block;
      position:relative;
      z-index:1;
      padding: 12px 0px 24px 0px;
      width:100%;
      margin:0 auto;
      //background:$white;
      
      h3{
        //color:$black;
      }
      p{
        display:none;
      }

    }
  }
}



#home__contact{
  padding:calc(3vw + 30px) 0 calc(3vw + 30px) 0;
  position:relative;
  .section-text__container{
    p{
      //font-weight:600;

    }
  }
  .section-phone__container{
    max-width:600px;
    width:100%;
    text-align:center;
    margin:0 auto 60px;
    .fa{
      transform:scale(0.8);
      margin-right:0.25em;
    }
    a{
      color:$black;
      font-size:2.4em;
      font-weight:900;
      //font-family:$headFont;
      text-align:center;
      position:relative;
      z-index:3;

      .shadow{
        position:absolute;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color:$green;
        left:0;
        top:0;
        right:0;
        bottom:0;
        //transform:translate(2px, -2px);
        color:transparent;
        z-index:-1;
        &:after{
          content:"";
          position:absolute;
          bottom:-4px;
          width:100%;
          left:0;
          background:$green;
          height:3px;
        }
      }
    }
  }
  .section-address__container{
    display:flex;
    max-width:600px;
    margin:24px auto 24px;
    width:100%;
    padding:0 12px;
    .column{
      flex: 1 1 50%;
      text-align:left;

      p{
   
        text-align:left;
      }
      a{
        color:$black;
        position:relative;
        &:before{
          content:"";
          position:absolute;
          bottom:0;
          left:0;
          right:0;
          height:1px;
          background:$green;
        }
      }
    }
  }
  form{
    width:100%;
    max-width:600px;
    margin:0 auto 90px;
    label{
      width:100%;
      display:block;
      margin:18px 0;
      .title{
        display:none;
        width:100%;
      }
      input,textarea{
        width:100%;
        &.empty{
          border-color:$red;
        }
      }
      textarea{
        height:160px;
        resize:none;
      }
      input[type="submit"]{
        background:$green;
        color:$black;
        font-weight:600;
        cursor:pointer;
        text-transform:uppercase;
        border-color:transparent;
        letter-spacing:0.2em;
      }

    }
  }

}
