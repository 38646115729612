

@font-face {
    font-family:"Barlow";
    font-display:swap;
    src:url("static/fonts/Barlow-Bold.woff2") format("woff2"),url("static/fonts/Barlow-Bold.woff") format("woff");
    font-style:normal;font-weight:700;
}

@font-face {
    font-family:"Barlow";
    font-display:swap;
    src:url("static/fonts/Barlow-Medium.woff2") format("woff2"),url("static/fonts/Barlow-Medium.woff") format("woff");
    font-style:normal;font-weight:500;
}

@font-face {
    font-family:"Barlow";
    font-display:swap;
    src:url("static/fonts/Barlow-Regular.woff2") format("woff2"),url("static/fonts/Barlow-Regular.woff") format("woff");
    font-style:normal;font-weight:400;
}

@font-face {
    font-family:"Barlow";
    font-display:swap;
    src:url("static/fonts/Barlow-Thin.woff2") format("woff2"),url("static/fonts/Barlow-Thin.woff") format("woff");
    font-style:normal;font-weight:300;
}

@font-face {
    font-family:"Head";
    font-display:swap;
    src:url("static/fonts/head.woff2") format("woff2"),url("static/fonts/head.woff") format("woff");
    font-style:normal;font-weight:400;
}
