#overlays{
   visibility: hidden;
   top:64px;
   left:0;
   right:0;
   bottom:0;
   position:fixed;
   z-index:-33;
   @include transition(z-index 0s 1s ease, visibility 0s 1s ease);
   &.show{
      visibility: visible;
      z-index:99;
      @include transition(z-index 0s 0s ease, visibility 0s 0s ease);
      .bg{
         opacity:0.7;
      }
   }
   .bg{
      top:64px;
      left:0;
      right:0;
      bottom:0;
      position:fixed;
      background:$white;
      opacity:0;
      @include transition(opacity 1s 0s ease);
   }
   .overlay-type-1{
      position:absolute;
      top:50%;
      left:50%;
      @include transform(translate(-50%, -50%));
      color:$black;

      visibility: hidden;
      z-index:-1;
      @include transition(z-index 0s 1s ease, visibility 0s 1s ease);
      &.show{
         visibility: visible;
         z-index:99;
         @include transition(z-index 0s 0s ease, visibility 0s 0s ease);
         .overlay-container{
            opacity:1;
            @include transform(translate(0%, 0));
            @include transition(opacity 1s 0.6s ease, transform 0.8s 0.4s ease);
         }
         .overlay-close-btn{
            opacity:1;
            @include transform(translate(0%, 0));
            @include transition(opacity 1s 0.6s ease, transform 0.8s 0.4s ease);
         }
      }
      .overlay-close-btn{
         position:absolute;
         width:48px;
         height:48px;
         top:-24px;
         right:-24px;
         border-radius:50%;
         background-color:$black;
         color:$white;
         cursor:pointer;
         opacity:0;
         @include transform(translate(0%, -12px));
         @include transition(opacity 0.4s 0s ease, transform 0.4s 0s ease, background-color 0.4s 0s ease);

         &:hover{
            background-color:$green;
         }
         span{
            position:absolute;
            text-align:center;
            width:100%;
            height:100%;
            top:0;
            left:0;
            @include rotate(-45);
            line-height:48px;
            font-size:2em;
            font-weight:800;
         }
      }
      .overlay-container{
         opacity:0;
         position:relative;
         max-height:90vh;
         @include transform(translate(0%, -12px));
         @include transition(opacity 0.4s 0s ease, transform 0.4s 0s ease);
         background:$white;
         box-shadow:0px 16px 24px -8px rgba(0,0,0,0.2);
         padding:0;
         border-radius:24px;
         overflow-y:scroll;
         .scroller{

         }
      }
   }
}

.overlay#language-currency-overlay{
   .overlay-container{
      padding:24px;
      .overlay-close-btn{
         @media only screen and (max-width:600px){

         }
      }
   }
   h4{
      font-family:$bodyFont;
      font-weight:700;
      font-size:22px;
      margin-bottom:12px;
      @media only screen and (max-height:600px){
         font-size:16px;
         margin-bottom:6px;
      }
   }
   ul{
      margin-bottom:12px;
      li{
         padding:3px 0;
         font-size:1em;
         font-weight:600;
         cursor:pointer;
         @include transition(all 0.4s 0s ease);
         @media only screen and (max-height:600px){
            padding:2px 0;
            line-height:1.2em;
         }
         &:hover{
            color:$green;
         }
      }
   }
   .languages-switcher-list{
      display:flex;
      flex-flow:wrap;
      li{
         flex:1 0;
         &.not-active{
            .icon{
               &:before{
                  opacity:0;
               }
            }
         }

      }
   }
}

.overlay#contact-overlay{
   max-width:800px;
   width:calc(100% - 48px);
   @media only screen and (max-width:600px){
      width:calc(100%);
      height:100%;
      bottom:0;
      top:auto;
      @include transform(translate(-50%, 0));
   }
   &.loading{
      filter:blur(3px);
   }
	.overlay-close-btn{
		@media only screen and (max-width:600px){
			right:24px;
			top:24px;
			background-color:$green;
		}
	}
   .overlay-container{
      width:100%;
      background:transparent;
      box-shadow:none;
      overflow:visible;
      @media only screen and (max-width:600px){
         overflow:scroll;
         height:90%;
         position:absolute;
         bottom:0;
      }

   }
   .scroller{


   }
   .text__container{
      width:280px;
      position:absolute;
      left:0;
      top:50%;
      @include transform(translate(0, -50%));
      height:calc(100% - 96px);
      padding:24px 24px;
      color:$white;
      @media only screen and (max-width:600px){
         position:static;
         height:auto;
         top:0;
         width:100%;
         @include transform(translate(0, 0%));
      }
      &:before{
         content:"";
         position:absolute;
         top:0;
         left:0;
         right:0;
         bottom:0;
         background:$black;
         box-shadow:0px 16px 24px -8px rgba(0,0,0,0.1);
         border-radius:24px 0 0 24px;
         z-index:-1;
         @media only screen and (max-width:600px){
            bottom:-30px;
         }
      }
      h2{
         color:$white;
      }
      .flag{
         width:16px;
         height:16px;
         img{
            width:16px;
            height:16px;
         }
      }

   }
   #contact-form{
      margin-left:280px;
      padding:24px 24px;
      display:flex;
      flex-flow:row wrap;
      background:$white;
      border-radius:24px;
      position:relative;
      z-index:2;
      box-shadow:0px 24px 54px 10px rgba(0,0,0,0.2);
      @media only screen and (max-width:720px){
         margin-left:0;
      }
      @media only screen and (max-width:520px){
         padding:12px 12px;
      }

   }

   input[type='submit']{
      -webkit-appearance:none;
      appearance:none;
      padding:0px 36px;
      border:solid 2px $orange;
      background:$orange;
      font-family:$bodyFont;
      line-height:36px;
      height:40px;
      font-size:14px;
      display:block;
      width:100%;
      color:$white;
      outline:none;
      border-radius:20px;
      box-shadow:0px 12px 24px -8px rgba(255,88,0,.8);
      position:absolute;
      bottom:-24px;
      left:36px;
      text-transform: uppercase;
      width:auto;
      font-weight:800;
      cursor:pointer;
      letter-spacing:0.06em;
      @include transition(all 0.8s 0s ease);
      &:hover{
         letter-spacing:0.1em;
      }
      @media only screen and (max-width:600px){
         position:relative;
         bottom:auto;
      }
   }
}

.overlay#contact-thanks-overlay{
   max-width:400px;
   width:calc(100% - 24px);
   .overlay-container{
      padding:24px 24px 24px 24px;
      background:$black;
      overflow:visible;
      .not-active{
         display:none;
      }
      .text__container{
         h2{
            margin-bottom:0.5em;
            color:$white;
         }
         p{
            color:$white;
            font-weight:600;
            margin:1em 0 1.5em;
         }
      }
      .button{
         position:absolute;
      }
   }
}
