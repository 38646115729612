input[type='text'], input[type='submit'], input[type='email'], textarea, select{
   -webkit-appearance:none;
   appearance:none;
   padding:0px 20px;
   border:solid 1px $black;
   background:transparent;
   font-family:$bodyFont;
   line-height:40px;
	color:$black;
   height:44px;
   display:inline-block;
   outline:none;
   border-radius:22px;
   //box-shadow:0px 2px 4px -3px rgba(0,0,0,0.1);
   @include transition(all 0.4s 0s ease);
   &::placeholder{
     color:$textColor;
   }
   &:focus{
      border-color:$black;
   }
   &.empty{
      border-color:$red;
   }
   &:disabled{
     filter:grayscale(100%);
     cursor:not-allowed;
   }
}
