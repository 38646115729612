$darkGreen: #007C56; //#0C6047;//#5E893E;
$green: #00FC81; //#0C6047;//#61925D;
$oldGreen: #007C56; //#0C6047; //old site
$orange:    #FF5800;
$black: 		#2d2c3f;
$textColor: #404044;
$white: 		#fff;
$red:       #E03939;
$subWhite:  #F1F1F1;
$newGreen: 	#007C56; //#0C6047;//#3E8952;
$brightGreen: #007C56; //#0C6047;//#19BC7C;

//green rgba(0,124,86,1);
//black rgba(22,25,43,1)


$black: rgba(20, 19, 21,1);
$black2: #1B1A27;
$black3: #23222f;


$boxShadow1: 0px 0px 24px -6px rgba(0,0,0,0.1);
$mainRadius: 40px;
$bodyFont: 'Manrope', Arial, Helvetica, sans-serif;
$headFont: 'Manrope', 'Barlow', 'AvenirNext', Arial, Helvetica, sans-serif;
//$headFont: 'AbrilFatface', 'AvenirNext', Arial, sans-serif;


$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);

$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);

$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);

$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);

$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint: cubic-bezier(0.23, 1, 0.32, 1);

$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(0.19, 1, 0.22, 1);

$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);

$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);

$easeInOutFast: cubic-bezier(1,0,0,1);

$authenticMotion: cubic-bezier(.4,0,.2,1);
