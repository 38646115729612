.button__container{
	display:block;
	margin:0px 0 0;
	width:100%;
	.button{
		margin-right:24px;
		&:last-child{
			margin-right:0;
		}
	}
}

.button{
	text-decoration:none;
	padding:0 22px 0 24px;
	position:relative;
	line-height:36px;
	height:38px;
	display:inline-block;
	color:$green;
	font-weight:400;
	font-family:$headFont;
	text-transform:uppercase;
	@include fluid-font(13px, 14px );
	letter-spacing:0.25em;
	@include transition(color 0.4s 0s ease);
	@include transform(translateZ(0));
	border-radius:20px;
	cursor:pointer;
	span.layer{
		position:absolute;
		top:-1px;
		left:0;
		right:0;
		bottom:-1px;
		border:solid 1px $green;
		border-radius:22px;
		opacity:1;
		z-index: -1;
		//@include transform(skew(-16deg, 0deg));
		@include transition(all 0.4s 0s ease);
		overflow:hidden;
		&:after{
			content:"";
			position:absolute;
			top:100%;
			left:-2px;
			width:calc(100% + 4px);
			height:calc(200%);
			background:$green;
			z-index:-1;
			opacity:1;
			@include transform(skewY(-8deg));
			transform-origin:right top;
			@include transition(all 0.8s 0s $easeInOutExpo);
		}
	}
	span.text{
		line-height:38px;
		height:38px;
		display:block;
	}
	&.dark{
		span.layer{
			border-color:$black;
			&:after{
				background:$black;
			}
		}
		&:hover{
			span.text{
				color:$white;
			}
		}
	}
	&.subdark{
		span.layer{
			border-color:$black;
			&:after{
				background:$black;
			}
		}
		&:hover{
			span.text{
				color:$white;
			}
		}
	}
	
	&.border-only{
		//background:$green;
		color:$white;
		&:hover{
			color:$black;
			.layer{
				//@include transform(skew(-16deg, 0deg));
				top:-2px;
				bottom:-2px;
				left:0px;
				
				right:0px;
				&:after{
					top:-100%;
				}
			}
		}
	}
	&.white{
		color:$white;
		span.layer{
			border-color:$white;
			&:after{
				background:$white;
			}
		}
		&:hover{
			span.text{
				color:$black;
			}
		}
	}
}
